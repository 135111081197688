.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  
}

header, footer {
  background-color: #011620;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 1000
}

header {
  position: sticky;
  top: 0;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

main {
  flex: 1;
  padding: 1rem;
}

.sidebar {
  width: 200px;
  background-color: #f5f5f5;
  padding: 1rem;
  position: fixed; /* Fixes the sidebar in position */
  right: 0; /* Positions it on the right side of the viewport */
  height: 100vh; /* Makes the sidebar full height */
  z-index: 1000; /* Keeps it above the main content */
}

.newsfeed {
  flex: 1;
  padding: 1rem;
  background-color: #f5f5f5; /* Very light grey background */
  margin-right: 200px;
}
