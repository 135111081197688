/* src/components/FilterMenu.css */

.filter-menu {
    position: absolute;
    top: 20px;
    right: 60px;
    width: 200px;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    z-index: 1000;
}

.filter-menu h4 {
    margin-bottom: 10px;
}

.filter-section {
    margin-bottom: 10px;
}

.filter-section h5 {
    margin: 5px 0;
}

.category-btn, .time-btn {
    display: inline-block;
    margin: 2px;
    padding: 5px 10px;
    background-color: #e0e0e0;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.category-btn.selected, .time-btn.selected {
    background-color: #007bff;
    color: #fff;
}

.search-input, .date-input {
    width: 100%;
    padding: 5px;
    margin-top: 2px;
    border-radius: 4px;
    border: 1px solid #ccc;
}
