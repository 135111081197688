/* General container and form styling */
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 80%;
}

.video-input-form {
  margin-bottom: 20px;
  position: relative;
  z-index: 10; /* Make sure the input form stays on top */
}

.video-input-form label {
  font-size: 1rem;
  margin-right: 10px;
}

.video-input-form input[type="text"] {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
  margin-right: 10px;
  position: relative;
  z-index: 20; /* Keep input above other elements */
}

.video-input-form button {
  padding: 8px 12px;
  font-size: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 20; /* Keep button above other elements */
}

.video-input-form button:hover {
  background-color: #45a049;
}

/* Controls styling */
.controls {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-time {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: bold;
}

/* Tag buttons styling */
.tag-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

.tag-button {
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.tag-button:hover {
  transform: scale(1.05);
}

.tag-button.verified-true { background-color: green; }
.tag-button.likely-true { background-color: lightgreen; color: black; }
.tag-button.opinion { background-color: yellow; color: black; }
.tag-button.likely-false { background-color: lightcoral; color: black; }
.tag-button.false { background-color: red; }

/* Selected button styling */
.tag-button.selected {
  border: 2px solid #333;
  background-color: #555;
  color: #fff;
}

/* Styling for the YouTube player container */
.video-responsive {
  display: inline-block;
  width: 50vw;
  height: 0;
  padding-bottom: 28.125vw;
  position: relative;
  margin: 0 auto;
}

#youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Lower than form to prevent overlap */
}

/* Timeline container styling */
.timeline {
  width: 100%;
  max-width: 640px;
  height: 20px;
  background-color: #ddd;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
  z-index: 5; /* Lower than input form but above player */
}

.highlight {
  position: absolute;
  height: 100%;
  border-radius: 5px;
}

.highlight.verified-true { background-color: green; }
.highlight.likely-true { background-color: lightgreen; }
.highlight.opinion { background-color: yellow; }
.highlight.likely-false { background-color: lightcoral; }
.highlight.false { background-color: red; }

.current-time {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: red;
}

/* Dropdown for editing section tags */
.highlight select {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  padding: 2px;
  border-radius: 4px;
}

/* Layout for video and summary sections */
.flex-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
}

.video-section {
  flex: 3;
  margin-right: 20px;
  text-align: center;
}

.summary-section {
  flex: 1;
  margin-left: 20px;
  max-width: 250px;
}

.summary-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.summary-section table {
  width: 100%;
  border-collapse: collapse;
}

.summary-section td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 0.9rem;
}

.summary-section tr:last-child td {
  border-bottom: none;
}

/* Styles for the draggable section handles */
.handle {
  width: 3px;
  height: 100%;
  position: absolute;
  background-color: #333;
  cursor: ew-resize;
  z-index: 2;
}

.handle.start {
  left: 0;
}

.handle.end {
  right: 0;
}
