/* Container for the entire article page */
.article-content {
  clear: both; /* Ensures content flows below the floating button */
  max-width: 800px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

/* Title styling */
.article-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
}

/* Image styling */
.image-container {
  width: 100%;
  outline-width: 100%;
  height: auto;
  margin: 15px 0;
}

/* Image styling */
.image-container img {
  width: 100%;
  height: auto;
}

/* Paragraph styling */
.article-content p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}

/* Source and date metadata */
.article-metadata {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

/* Links inside paragraphs */
.article-content a {
  color: #1a0dab;
  text-decoration: none;
}

.article-content a:hover {
  text-decoration: underline;
}

/* Video placeholder styling */
.video-placeholder {
  width: 100%;
  height: 180px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  margin: 15px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

/* Play button styling */
.play-button {
  font-size: 3rem;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.view-original-btn:hover {
  background-color: #0056b3;
}

.view-original-btn {
  display: inline-block; /* Allows the button width to fit its content */
  margin: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px 15px; /* Controls padding around the text */
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
}

.button-container {
  text-align: center;
  margin-bottom: 10px; /* Adjust spacing as needed */
}


.loading {
  font-size: 1.5em;
  text-align: center;
  margin-top: 50px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 50px auto;
  display: block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup button {
  display: block;
  width: 100%;
  padding: 5px;
  margin: 2px 0;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.popup button:nth-child(1) {
  background-color: green; /* Verified True */
}

.popup button:nth-child(2) {
  background-color: lightgreen; /* Likely True */
  color: black; /* Darker text for contrast */
}

.popup button:nth-child(3) {
  background-color: yellow; /* Opinion */
  color: black;
}

.popup button:nth-child(4) {
  background-color: lightcoral; /* Likely False */
  color: black;
}

.popup button:nth-child(5) {
  background-color: red; /* False */
}

.popup button:hover {
  opacity: 0.9;
}

.popup button:hover {
  background-color: #0056b3;
}

.scale-bar {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center; /* Vertically center all content */
  justify-content: center; /* Horizontally center prompt text */
  width: 700px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  background-color: white;
}

.scale-bar > div {
  height: 100%;
}


.article-image.accurate {
  outline: 10px double green;
}

.article-image.misleading {
  outline: 10px double coral;
}

.article-image.inaccurate {
  outline: 10px double red;
}

.image-container {
  position: relative;
  display: inline-block; /* This keeps the container and its content inline */
  margin: 10px;
  z-index: 1; /* Ensure images are behind the header bar */
}

.rating-buttons {
  display: none;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.image-container:hover .rating-buttons {
  display: flex;
}

.accurate-button, .misleading-button, .inaccurate-button {
  background-color: green; /* change accordingly */
  color: white;
  margin: 10px;
  font-size: 1.2em; /* Make the text larger */
  transition: background-color 0.3s, transform 0.3s;
  padding: 15px 25px;
}

.misleading-button {
  background-color: coral;
}

.inaccurate-button {
  background-color: red;
}

.accurate-button:hover {
  background-color: darkgreen;
  transform: scale(1.1);
}

.misleading-button:hover {
  background-color: darkorange;
  transform: scale(1.1);
}

.inaccurate-button:hover {
  background-color: darkred;
  transform: scale(1.1);
}

.highlight-prompt {
  position: absolute;
  color: #333;
  font-size: 1.2em;
  text-align: center;
  pointer-events: none; /* Prevent the text from interfering with interactions with the scale bar */
}