/* Article Card Styles */
.article {
  display: flex;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 1rem;
  border-radius: 5px;
  background-color: #fff;
  border: 5px solid white;
  transition: border-color 0.3s ease;
  gap: 1rem;
}

/* Selected Border Colors for Content Types */
.article.selected-verified-true {
  border-color: green !important;
}

.article.selected-likely-true {
  border-color: lightgreen !important;
}

.article.selected-opinion {
  border-color: yellow !important;
}

.article.selected-likely-false {
  border-color: lightcoral !important;
}

.article.selected-false {
  border-color: red !important;
}

/* Article Content Layout */
.article-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}


/* Article Headline Styling */
.article h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

/* Paragraph Styling */
.article p {
  margin: 0.5rem 0;
}

/* Article Source Image Styling */
.source-logo {
  max-width: 60px;
  height: auto;
}


/* Ratings Box Styling */
.ratings-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

}

.content-type-button {
  margin: 5px;
  padding: 10px;
  border: 2px solid; /* Set border color based on type */
  background-color: white; /* Default background is white */
  color: black; /* All text is black */
  transition: transform 0.2s ease, background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  outline: none; /* Remove focus outline */
}

/* Specific Colors for Content Type Buttons */
/* Border and text color for different types */
.content-type-button.verified-true {
  border-color: green;
}

.content-type-button.likely-true {
  border-color: lightgreen;
}

.content-type-button.opinion {
  border-color: yellow;
}

.content-type-button.likely-false {
  border-color: lightcoral;
}

.content-type-button.false {
  border-color: red;
}

.content-type-button:hover {
  transform: scale(1.05);
  color: black; /* Dark text for better contrast */
}

/* Hover state - explicitly fill with border color */
.content-type-button.verified-true:hover {
  background-color: green;
}

.content-type-button.likely-true:hover {
  background-color: lightgreen;
}

.content-type-button.opinion:hover {
  background-color: yellow;
}

.content-type-button.likely-false:hover {
  background-color: lightcoral;
}

.content-type-button.false:hover {
  background-color: red;
}

/* Selected Button State */
.content-type-button.selected {
  color: black; /* Dark text for readability */
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

/* Selected button state - explicitly fill with border color */
.content-type-button.selected.verified-true {
  background-color: green;
}

.content-type-button.selected.likely-true {
  background-color: lightgreen;
}

.content-type-button.selected.opinion {
  background-color: yellow;
}

.content-type-button.selected.likely-false {
  background-color: lightcoral;
}

.content-type-button.selected.false {
  background-color: red;
}

/* Articles Container */
.articles {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Styling for the slider label to align elements properly */
.slider-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 10px;
  color: black;
}

/* Container to help align the slider */
.slider-container {
  position: relative;
  width: 100%;
  margin-top: 5px;
}

/* Styling for the accuracy and bias scales */
.importance-scale,
.bias-scale,
.exclusive-scale {
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, red, yellow, green);
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 0;
}

.importance-scale::-webkit-slider-runnable-track,
.bias-scale::-webkit-slider-runnable-track,
.exclusive-scale::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, red, yellow, green);
  border-radius: 5px;
}

.importance-scale::-webkit-slider-thumb,
.bias-scale::-webkit-slider-thumb, 
.exclusive-scale::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: black;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -6px; /* Align the thumb with the track */
}

.importance-scale::-moz-range-thumb,
.bias-scale::-moz-range-thumb,
.exclusive-scale::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
  border-radius: 50%;
}

.action-buttons {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.detailed-rating-button {
  background-color: white;
  color: #011620;
  border-color: #011620;
  border-radius: 4px;
  cursor: pointer;
  padding: 7px;
}

.detailed-rating-button:hover, {
  scale: 1.2;
}

/* Article Image Styling (left section) */
.article-image {
  flex: 0 0 20%;
}

.article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

/* Article Content Styling (middle section) */
.article-content {
  flex: 1 0 40%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Article Ratings and Buttons Styling (right section) */
.article-ratings-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
}

.article-source-inline {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust as needed for spacing between the image and text */
}

.source-text {
  font-weight: bold; /* Optional: make the source name stand out */
}

