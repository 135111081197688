.header {
  display: flex;
  align-items: center; /* Vertically centers all elements */
  justify-content: space-between; /* Spreads out the left, center, and right sections */
  padding: 1rem;
  background-color: #011620;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-left {
  flex: 1; /* Takes up space on the left */
  display: flex;
  align-items: center;
}

.header-hat {
  height: 90px; /* Adjust to the desired height */
  width: auto; /* Maintain aspect ratio */
  cursor: pointer; /* Indicates the logo is clickable */
}

.header-center {
  flex: 2; /* Centre section takes more space to ensure center alignment */
  display: flex;
  justify-content: center; /* Centers content within this section */
  align-items: center;
}

.header-center img {
  height: 60px; /* Keep masthead height consistent with the logo */
  width: auto; /* Maintain aspect ratio */
  padding-top: 10px;
}

.header-right {
  flex: 1; /* Takes up space on the right */
  display: flex;
  justify-content: flex-end; /* Align buttons to the far right */
  align-items: center; /* Vertically centers the buttons */
  gap: 1rem; /* Adds spacing between buttons */
}

.header-btn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007BFF;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.header-btn:hover {
  background-color: #0056b3;
}
